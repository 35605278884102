// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bQ_k4";
export var caseStudyBackground__lineColor = "bQ_kW";
export var caseStudyHead__imageWrapper = "bQ_m0";
export var caseStudyProjectsSection = "bQ_k5";
export var caseStudyQuote__bgRing = "bQ_k1";
export var caseStudyQuote__bottomVideo = "bQ_lZ";
export var caseStudyQuote__light = "bQ_l7";
export var caseStudyQuote__lightLast = "bQ_m1";
export var caseStudyVideo__ring = "bQ_k8";
export var caseStudy__bgDark = "bQ_kS";
export var caseStudy__bgLight = "bQ_kR";
export var caseStudy__bgLightReverse = "bQ_l0";